.App {
  text-align: center;
}



/*------ NEW STYLES START ---*/
.dark {
  color: #111111;
}
h1.title {
  background-color: #efefef;
  padding: 12px;
  font-size: 1.5rem;
  margin: 1rem 1rem 0px;
  font-weight: 600;
}
.border-rounded {
  border-radius: 3px;
}
.product-img img {
  max-width: 100%;
}
.mt-1 {
  margin-top: 1rem;
}
.me-1 {
  margin-right: 1rem;
}
.ms-1 {
  margin-left: 1rem;
}
.mb-1 {
  margin-bottom: 1rem;
}
.mx-1 {
  margin:0 1rem;
}

.p-1 {
  padding: 1rem;
}
.pt-1 {
  padding-top: 1rem;
}
.pe-1 {
  padding-right: 1rem;
}
.ps-1 {
  padding-left: 1rem;
}
.pb-1 {
  padding-bottom: 1rem;
}
.px-1 {
  padding:0 1rem;
}
.products ul {
  list-style: none;
  padding: 0px;
}
.border {
  border: 1px solid #efefef;
  overflow: hidden;
}
.product-info {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 1rem;
  flex-direction: column;
  gap: 6px;
}
.product-info h4 {
  margin: 0px;
  font-size: 14px;
}
.price {
  display: flex;
  column-gap: 5px;
}
.currency {
  font-size: 12px;
}
.provider ul {
  list-style: none;
  margin: 1rem;
  padding: 1rem;
}
.provider ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 0px;
  border-bottom: 1px solid #efefef;
}
.provider ul li:last-child {
  border-bottom: 0px solid #efefef;
}
button {
  background: rgb(145, 190, 58);
  background: linear-gradient(135deg, rgba(145, 190, 58, 1) 0%, rgba(0, 126, 57, 1) 47%, rgba(8, 165, 79, 1) 100%);
  border: 0px;
  padding: 1rem;
  width: calc(100% - 2.5rem);
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  color: #FFFFFF;
  border-radius: 3px;
  margin: 0px 1rem;
}
button:hover {
  background: rgb(0,126,57);
  background: linear-gradient(90deg, rgba(0,126,57,1) 0%, rgba(145,190,58,1) 74%, rgba(8,165,79,1) 100%);
}
p.failure {
  background-color: #ffc5c5;
}
p.success {
  background-color: #6cffae;
}
p.fetching {
  background-color: #97e8ff;
}
/*------ NEW STYLES END ---*/